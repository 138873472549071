import React from 'react'
import { AviaryIIIFPlayer } from 'aviary-iiif-player';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../hooks';

const Main = () => {
    const navigate = useNavigate();
    const query = useQuery()
    const [manifestUrl, setManifestUrl] = React.useState(null)
    const url = query.get('manifest')
    React.useEffect(() => {
        if (!url) {
            navigate(`../`, { replace: true });
        }
        setManifestUrl(url)
        
    }, [url])

    return (
        <>
            {(manifestUrl) ? (
                <div>
                    <div>
                        <AviaryIIIFPlayer manifest={manifestUrl} />
                    </div>
                </div>
            )
                : ""
            }

        </>

    )
}

export default Main
