import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../assets/images/iiif.png';
import { searchURL } from '../features'
import { useDispatch } from 'react-redux'

const Home = () => {
    const navigate = useNavigate()
    const [input, setInput] = useState('')
    const dispatch = useDispatch();
    const { manifest } = useParams()
    const [manifestUrl, setManifestUrl] = useState(null)

    const push = () => {
        dispatch(searchURL(input));
        navigate({
            pathname: '/player',
            search: `?manifest=${input}`,
          })
    }
    useEffect(() => {
        document.title = "Aviary - IIIF Player"
    }, []);

    return (
        <div className='grid place-items-center main-bg h-screen px-20'>
            <div className="holder">
                <img src={Logo} className="w-14 mx-auto" />
                <Link to='/main?manifest_url=sec://url/if/id/manifest'>
                    <h1 className='subpixel-antialiased mt-5 mb-10 font-bold text-center text-3xl'>Please Enter the Manifest URL</h1>
                </Link>
                <div className='flex bg-white rounded-2xl border-2 border-gray-600 px-10 py-10'>
                    <input data-testid='input' className='border-2 outline-none focus-within:shadow-2xl border-r-0 bg-white rounded-md rounded-br-none rounded-tr-none text-lg px-4 py-3 w-full' placeholder='Please Enter the URL' value={input} onChange={(e) => setInput(e.target.value)} />
                    <button className=' px-5 py-3 bg-white border-2 border-l-0 text-black rounded-md rounded-bl-none rounded-tl-none font-semibold' onClick={push}>Next</button>
                </div>
            </div>
        </div>
    )
}

export default Home
