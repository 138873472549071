// type
export const SEARCH_URL = 'SEARCH_URL';

// action
export const searchURL = (url) => {
	return {
		type: SEARCH_URL,
        url
	}
}

// reducer
const defaultURL = "";

const searchURLReducer = (state = defaultURL, action) => {
	switch(action.type){
		case SEARCH_URL: return action.url
		default: return state
	}
}

export default searchURLReducer