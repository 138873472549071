import React, { useEffect } from 'react';
import './App.css';
import Home from './components/home';
import Main from './components/player';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import store from './features/store'
import { Provider } from 'react-redux'

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<Provider store={store}><Home /></Provider>} />
          <Route exact path='/player' element={<Provider store={store}><Main /></Provider>} />
        </Routes>
      </div>
    </Router>

  );
}

export default App;
